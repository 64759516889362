<template>
    <div class="box">
        <!-- 表单数据 -->
        <div style="margin-bottom:50px;">
            <el-table
                :data="list"
                 height="200"
                style="width: 100%;"
            >
                <el-table-column
                    prop="created_at"
                    label="修改时间"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="number"
                    label="数值"
                    width="180"
                >
                </el-table-column>
                <el-table-column
                    prop="created_id"
                    label="调整人"
                >
                </el-table-column>
                <el-table-column
                    prop="log_des"
                    label="描述"
                >
                </el-table-column>
            </el-table>
        </div>
        <el-form
            :model="model"
            ref="model"
            label-width="100px"
        >
            <!-- 用户信息 -->
            <el-form-item label="用户信息">
                <el-table
                    class="b-card"
                    :data="user"
                    border
                    style="width: 100%"
                >
                    <el-table-column
                        prop="username"
                        label="用户名"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="nickname"
                        label="微信名"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="phone"
                        label="手机号码"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="LV"
                        label="会员等级"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="money"
                        label="储值"
                        v-if="type === 1"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="score"
                        label="积分"
                        v-if="type === 2"
                    >
                    </el-table-column>
                </el-table>
            </el-form-item>

            <!-- 积分修改的话 需要修改对应的积分数值 -->
            <el-form-item
                label="选择类型"
                v-if="type == 2"
            >
                <el-radio-group v-model="model.o_type">
                    <el-radio :label="1">全部</el-radio>
                    <el-radio :label="2">成长值</el-radio>
                    <el-radio :label="3">积分值</el-radio>
                </el-radio-group>

            </el-form-item>
            <!-- 修改区 -->
            <el-form-item
                label="数值修改"
                style="margin-top:50px;"
                 :rules="{
                    required: true, message: '描述不能为空', trigger: 'blur'
                }"
            >
                <el-radio-group v-model="model.isAdd">
                        <el-radio :label="1">增加</el-radio>
                        <el-input
                            type="number"
                            style="width:150px;"
                            placeholder="请输入数目"
                            v-model="model.addNumber"
                            :min="0"
                        >
                        </el-input>
                        <el-radio :label="2" style="margin-left:50px;">减少</el-radio>
                        <el-input
                            type="number"
                            :min="0"
                            style="width:150px;"
                            placeholder="请输入数目"
                            v-model="model.reduceNumber"
                        >
                        </el-input>
                </el-radio-group>
            </el-form-item>

            <el-form-item
                label="描述"
                style="margin-top:50px;"
                :rules="{
                    required: true, message: '描述不能为空', trigger: 'blur'
                }"
            >
                <el-col :span="8">
                    <el-input v-model="model.des"></el-input>
                </el-col>
            </el-form-item>

            <el-form-item>
                <el-button
                    type="primary"
                    @click="onSubmit"
                >调整</el-button>
                <el-button @click="close">取消</el-button>
            </el-form-item>
        </el-form>

    </div>
</template>

<script>
import UserService from '@admin/services/UserService'
import flatry from '@admin/utils/flatry'

export default {
  name: 'ChangeUserInfo',
  data () {
    return {
      id: '',
      type: '',
      user: [],
      list: [],
      model: {
        des: '',
        isAdd: 1,
        addNumber: '',
        reduceNumber: '',
        o_type: 1
      }
    }
  },

  methods: {
    async getData (id, type) {
      this.model.o_type = 1
      const { data } = await flatry(
        UserService.changeData({ id: id, type: type }, true)
      )
      this.id = id
      this.type = type
      if (data) {
        this.list = data.data.list
        this.user = data.data.user
      }
    },
    async onSubmit () {
      let param = {
        id: this.id,
        type: this.type,
        model: this.model
      }
      const { data } = await flatry(
        UserService.changeData(param)
      )
      if (data) {
        this.$message.success(data.msg)
        this.model.des = ''
        this.model.addNumber = ''
        this.model.reduceNumber = ''
        this.$emit('close')
      }
    },
    async close () {
      this.$emit('close')
    }
  }
}
</script>
<style lang='scss' scoped>

</style>
