<template>
  <div class="box">
    <el-button
      type="primary"
      size="medium"
      @click="downloadFile"
    >
      数据导出
    </el-button>
    <div class="box-toolbar">
      <div class="button-bar">

        <router-link
          v-if="$can('user/create')"
          :to="{ path: '/user/create' }"
        >
          <el-button
            type="primary"
            size="medium"
          >
            新建会员
          </el-button>
        </router-link>

        <!-- <export-button
          v-if="$can('user/export')"
          :disabled="loading"
          :api="'user/export'"
          type="default"
          size="small"
        >
          会员导出
        </export-button> -->
      </div>
      <div class="box-item">
        <el-input
          placeholder="手机号码\用户名\ID"
          v-model="content"
          class="input-with-select"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      border
      height="70vh"
      v-loading="loading"
      :stripe="true"
      :data="lists"
    >
      <el-table-column
        width="90"
        class-name="text-mono"
        prop="id"
        label="Id"
      >
      </el-table-column>
      <el-table-column
        prop="username"
        width="150"
        label="用户名称"
      >
      </el-table-column>
      <el-table-column
        prop="nickname"
        width="150"
        label="微信名称"
      >
      </el-table-column>
      <el-table-column
        prop="phone"
        width="150"
        label="电话号码"
      >
      </el-table-column>
      <el-table-column
        width="60"
        align="center"
        label="头像"
      >
        <template slot-scope="scope">
          <bp-avatar :avatar="scope.row.avatar"></bp-avatar>
        </template>
      </el-table-column>
      <el-table-column
        width="60"
        prop="sex"
        label="性别"
      >
      </el-table-column>
      <el-table-column
        align="center"
        width="179"
        class-name="text-mono"
        prop="member_type"
        label="会员类型"
      >
      </el-table-column>
      <el-table-column
        align="center"
        width="179"
        class-name="text-mono"
        prop="experience"
        label="积分值"
      >
      </el-table-column>
      <el-table-column
        align="center"
        width="179"
        class-name="text-mono"
        prop="integral"
        label="可用积分"
      >
      </el-table-column>
      <el-table-column
        align="center"
        width="179"
        class-name="text-mono"
        prop="money"
        label="金额"
      >
      </el-table-column>
      <el-table-column
        class-name="text-mono"
        width="130"
        prop="created_at"
        label="注册时间"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        width="350"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            plain
            size="mini"
            @click="View(scope.row)"
          >
            查看
          </el-button>
          <el-button
            plain
            size="mini"
            :type="scope.row.status == 10?'danger':'primary'"
            @click="Prohibit(scope.row)"
          >
            {{scope.row.status == 10?'禁用':'启用'}}
          </el-button>
          <el-button
            plain
            size="mini"
            type="primary"
            @click="Money(scope.row)"
          >
            储值
          </el-button>
          <el-button
            plain
            size="mini"
            type="primary"
            @click="Integral(scope.row)"
          >
            积分
          </el-button>
          <el-button
            plain
            size="mini"
            @click="ViewOrder(scope.row)"
          >
            订单
          </el-button>
        </template>
        <!-- <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button
              plain
              size="mini"
              type="primary"
            >
              操作<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="$can('admin/member/update')"
                @click.native="handleUserEdit(scope.row)"
              >
                编辑
              </el-dropdown-item>
              <el-dropdown-item
                v-if="$can('admin/member/delete')"
                @click.native="handleUserDelete(scope.row)"
              >
                删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template> -->
      </el-table-column>
    </el-table>
    <div class="bp-pages">
      <page
        :pageSize="limit"
        :count="count"
        @change="pageChange"
        @changeSize="changeSize"
      >
      </page>
    </div>
    <!-- 储值与积分 -->
    <el-dialog
      width="70%"
      class="diao"
      :title="diablogTitle"
      :visible.sync="dialogTableVisible"
    >
      <div>
        <change
          ref="change_data"
          @close="close"
        />
      </div>
    </el-dialog>
    <!-- 查看 -->
    <el-dialog
      title="会员查看"
      :visible.sync="dialogMember"
      width="50%"
    >
      <ViewUser
        ref="view_user"
        @close="close"
      />
    </el-dialog>

    <!-- 查看订单 -->
    <el-dialog
      title="会员查看"
      :visible.sync="dialogOrder"
      width="80%"
    >
      <Order
        ref="user_order"
        @close="close"
      />
    </el-dialog>

  </div>
</template>

<script>
import BpAvatar from '@admin/components/Avatar'
import UserService from '@admin/services/UserService'
import pagination from '@admin/mixins/PaginationMixin'
import Page from '@admin/components/Pagination'
import Change from './Change'
import flatry from '@admin/utils/flatry'
import ViewUser from './components/View'
import Order from './components/Order'
import FileService from '@admin/services/FileService'

export default {
  components: { BpAvatar, Page, Change, ViewUser, Order },
  mixins: [pagination],
  data () {
    return {
      dialogTableVisible: false,
      dialogMember: false,
      dialogOrder: false,
      diablogTitle: '',
      searchType: [
        {
          id: 0,
          name: '手机号码'
        },
        {
          id: 1,
          name: '用户名'
        }
      ],
      content: '',
      loading: true,
      function: function (param) {
        return UserService.all(param)
      }
    }
  },
  methods: {
    async searchGo (isSave = false) {
      if (isSave === false) {
        this.page = 1
      }
      this.loading = true

      let param = {
        limit: this.limit,
        page: this.page,
        content: this.content
      }
      this.where = {
        content: this.content
      }

      const data = await this.function(param)

      if (data) {
        this.lists = data.data
        this.count = data.count
      }

      this.loading = false
    },
    // 积分按钮
    Integral (e) {
      setTimeout(() => {
        this.$refs.change_data.getData(e.id, 2)
      }, 10)
      this.diablogTitle = '积分调整'
      this.dialogTableVisible = true
    },
    // 金钱/储值 按钮
    Money (e) {
      setTimeout(() => {
        this.$refs.change_data.getData(e.id, 1)
      }, 10)
      this.diablogTitle = '积分调整'
      this.dialogTableVisible = true
    },
    close () {
      this.dialogTableVisible = false
      this.dialogMemberClose = false
      this.searchGo(true)
    },
    // 禁用按钮
    async Prohibit (e) {
      const { data } = await flatry(UserService.Prohibit({ id: e.id }))
      if (data) {
        this.$message.success(data.msg)
        this.searchGo()
      }
    },
    // 查看按钮
    View (e) {
      setTimeout(() => {
        this.$refs.view_user.getData(e.id)
      }, 10)
      this.dialogMember = true
    },
    // 订单按钮
    ViewOrder (e) {
      setTimeout(() => {
        this.$refs.user_order.getData(e.id)
      }, 10)
      this.dialogOrder = true
    },
    async downloadFile () {
      let param = {
        type: 'member'
      }
      const { data } = await flatry(FileService.downloadFile(null, param))
      if (data) {
        console.log(data)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// .diao{
//   min-height: 600px;
//   height: 600px;
// }
</style>
