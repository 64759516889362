<template>
  <div class="box">
    <table v-if="data" class="el-table">
      <tbody>
        <tr class="el-table__row">
          <td>卡号</td>
          <td>{{data.num}}</td>
        </tr>
        <tr class="el-table__row">
          <td>微信名</td>
          <td>{{data.nickname}}</td>
        </tr>
        <tr class="el-table__row">
          <td>昵称</td>
          <td>{{data.username}}</td>
        </tr>
        <tr class="el-table__row">
          <td>电话</td>
          <td>{{data.phone}}</td>
        </tr>
        <tr class="el-table__row">
          <td>出生日期</td>
          <td>{{data.birthday_}}</td>
        </tr>
        <tr class="el-table__row">
          <td>所在地</td>
          <td>{{data.city}}</td>
        </tr>
        <tr class="el-table__row">
          <td>会员等级</td>
          <td>{{data.LV}}</td>
        </tr>
        <tr class="el-table__row">
          <td>积分</td>
          <td>{{data.score}}</td>
        </tr>
        <tr class="el-table__row">
          <td>状态</td>
          <td>{{data.status}}</td>
        </tr>
        <tr class="el-table__row">
          <td>创建时间</td>
          <td>{{data.created_at}}</td>
        </tr>
        <tr class="el-table__row">
          <td>更新时间</td>
          <td>{{data.updated_at}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import UserService from '@admin/services/UserService'
import flatry from '@admin/utils/flatry'

export default {
  name: 'UserView',
  data () {
    return {
      data: null
    }
  },

  methods: {
    async getData (id) {
      const { data } = await flatry(UserService.view(id, true))
      if (data) {
        console.log(data.data)
        this.data = data.data
      }
    }
  }
}
</script>
<style lang='' scoped>
</style>
