<template>
<div class="box">
    <div class="box-toolbar">

      <div class="box-item">
        <el-input
          placeholder="订单事件"
          v-model="created_at"
          clearable
        >
        </el-input>
      </div>
      <div class="box-item">
       <el-input
          placeholder="订单号"
          v-model="order_no"
          clearable
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-select
          v-model="type"
          placeholder="订单类型"
          @change="changeTypesStatus"
        >
          <el-option
            v-for="(item,index) in Types"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      :stripe="true"
      :data="lists"
      height="75vh"
    >
      <el-table-column
        width="220"
        prop="order_no"
        label="订单号"
      >
      </el-table-column>

      <el-table-column
        width="220"
        prop="created_at"
        label="订单时间"
      >
      </el-table-column>

      <el-table-column
        width="100"
        prop="status"
        label="订单状态"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == 1"
            type="info"
            size='mini'
            plain
          >未支付</el-button>
          <el-button
            v-if="scope.row.status == 2"
            type="primary"
            size='mini'
            plain
          >已支付</el-button>
          <el-button
            v-if="scope.row.status == 3"
            type="success"
            size='mini'
            plain
          >已完成</el-button>
          <el-button
            v-if="scope.row.status == 5"
            type="warning"
            size='mini'
            plain
          >已退款</el-button>
          <el-button
            v-if="scope.row.status == 6"
            type="warning"
            size='mini'
            plain
          >部分退款</el-button>
          <el-button
            v-if="scope.row.status == 8"
            type="info"
            size='mini'
            plain
          >已关闭</el-button>
        </template>
      </el-table-column>

      <el-table-column
        prop="type"
        label="订单类型"
      >
        <template slot-scope="scope">
          {{scope.row.type == 1?'堂食':'带走'}}
        </template>
      </el-table-column>

      <el-table-column
        prop="actual_payment"
        label="支付金额"
      >
      </el-table-column>

      <el-table-column
        prop="message"
        label="备注"
      >
      </el-table-column>

       <el-table-column
        prop="store_id"
        label="门店"
      >
        <template slot-scope="scope">
          {{scope.row.store.bsName}}
        </template>
      </el-table-column>

      <el-table-column
        width="180"
        prop="payment_at"
        label="付款时间"
      >
      </el-table-column>

    </el-table>
    <page
      :pageSize="limit"
      :count="count"
      @change="pageChange"
      @changeSize="changeSize"
    >
    </page>
  </div>
</template>

<script>
import UserService from '@admin/services/UserService'
// import flatry from '@admin/utils/flatry'
import Page from '@admin/components/Pagination'
import pagination from '@admin/mixins/PaginationMixin'

export default {
  name: 'UserOrder',
  components: { Page },

  data () {
    const Types = [
      {
        id: 1,
        name: '堂食'
      },
      {
        id: 2,
        name: '打包'
      }
    ]
    return {
      noLoading: true,
      id: '',
      loading: true,
      function: function (param) {
        return UserService.order(param)
      },
      data: null,
      created_at: '',
      order_no: '',
      type: '',
      Types: Types
    }
  },
  mixins: [pagination],
  methods: {
    async getData (id) {
      this.lists = []
      this.id = id
      await this.searchGo()
    },
    async searchGo () {
      this.page = 1
      let param = {
        user_id: this.id,
        created_at: this.created_at,
        order_no: this.order_no,
        type: this.type
      }
      this.where = param
      await this.getList(param)
    },
    changeTypesStatus () {
      this.searchGo()
    }
  }

}

</script>
<style lang='' scoped>

</style>
